import { React, useState, useRef } from 'react'
import { Link,  useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CAlert,
  CFormCheck
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilContact, cilEnvelopeClosed, cilLockLocked, cilMobile, cilUser } from '@coreui/icons'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'

const Register = () => {

  const navigate = useNavigate();

  // const ErrorAlert = document.getElementById('error_msg')
  const [errorMsgAlert, seterrorMsgAlert] = useState('')
  const [alertBox, setalertBox] = useState(false)
  const [successBox, setsuccessBox] = useState(false)

  const recaptchaRef = useRef()

  const reCaptchaonChange = () => {
    console.info('Recaptcha Success')
  }

  const registerFunction = () => {

    let first_name = document.getElementById("firstName").value;
    let last_name = document.getElementById("lastName").value;
    let mobile = document.getElementById("mobileNumber").value;
    let designation = document.getElementById("designation").value;
    let email = document.getElementById("emailAddress").value;
    let password = document.getElementById("userPassword").value;
    let password_confirmation = document.getElementById("confirmPassword").value;
    let registerRecaptcha = recaptchaRef.current.getValue();

    // Should use same Key values from API
    const userCredentials = { 
                              first_name,
                              last_name,
                              mobile,
                              email,
                              designation,
                              password,
                              password_confirmation,
                              registerRecaptcha
                            }

    // Register Function
    axios.post("https://api.indgiants.in/api/register", userCredentials)
    .then(res => {
      if (res.status === 200) {
        // console.info(res.status)
        setsuccessBox(true)
        setalertBox(false)
        setTimeout(() => {
          navigate('/register-success')
        }, 2000)
        // Register Mail Function
        registrationEmail(email)
      } else {
        return
      }
    })
    .catch((error) => {
      error = error.response.data.message;
      setalertBox(true)
      seterrorMsgAlert(error);
      setsuccessBox(false);
    })
  }

  const registrationEmail = async(email) => {
    
    const userDetails = new FormData();
    userDetails.append("email", email);

    let url = await fetch("https://api.indgiants.in/api/register-mail", {
      method: "POST",
      body: userDetails,
    })
    .then((res) => {
      console.info(res)
    })
    .catch((err) => {
      console.error(err)
    })

  }


  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={10}>
            <CCardGroup>
              <CCard className="p-4 w-70 mt-2" style={{ textAlign: "left" }}>
                <CCardBody>
                  <CForm>
                    <h1>Register</h1>
                    <p className="text-medium-emphasis">Create your account</p>
                    {
                      alertBox ? 
                      <CAlert color="danger" id="error_msg">
                        { errorMsgAlert }
                      </CAlert>
                    :
                    null
                    }

                    {
                      successBox ? 
                      <CAlert color="success" id="success_msg">
                        Your registration is successfull
                      </CAlert>
                    :
                    null
                    }
                    <CRow>
                      <CCol sm={6} >
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilUser} />
                          </CInputGroupText>
                          <CFormInput placeholder="First Name" autoComplete="username" id="firstName" required/>
                        </CInputGroup>
                      </CCol>
                      <CCol sm={6} >
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilUser} />
                          </CInputGroupText>
                          <CFormInput placeholder="Last Name" autoComplete="username" id="lastName" required/>
                        </CInputGroup>
                      </CCol>
                      <CCol sm={6}>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilMobile} />
                        </CInputGroupText>
                        <CFormInput placeholder="Mobile Number" autoComplete="username" id="mobileNumber" required/>
                      </CInputGroup>
                      </CCol>
                      <CCol sm={6}>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilEnvelopeClosed} />
                        </CInputGroupText>
                        <CFormInput placeholder="Email" autoComplete="username" id="emailAddress" required/>
                      </CInputGroup>
                      </CCol>
                      <CCol sm={12}>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilContact} />
                        </CInputGroupText>
                        <CFormInput placeholder="Designation/Affiliation" autoComplete="username" id="designation" required/>
                      </CInputGroup>
                      </CCol>
                      <CCol sm={6}>
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          id="userPassword"
                          required
                        />
                      </CInputGroup>
                        </CCol>
                        <CCol sm={6}>
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type="password"
                          placeholder="Confirm Password"
                          autoComplete="current-password"
                          id="confirmPassword"
                          required
                        />
                      </CInputGroup>
                      </CCol>
                      <CInputGroup className="mb-2">
                        <CFormCheck id="servicesCheck" label="I agree to Terms of Services &amp; Privacy Policy of Indgiants Pvt.Ltd." required/>
                      </CInputGroup>                    
                      <CInputGroup className="mb-4">
                        <CFormCheck id="agreementCheck" label="I accept to sign a non-disclosure agreement upon successful activation" required/>
                      </CInputGroup>
                    </CRow>
                    <CRow>
                      <ReCAPTCHA 
                        ref={recaptchaRef}
                        sitekey="6LcGpxkdAAAAAFvFV2Y88FpY4mbHJNQVBwEe9lPx"
                        onChange={reCaptchaonChange}
                        className="mb-3"
                      />
                    </CRow>
                    <CRow>
                      <CCol xs={8}>
                        <CButton color="primary" className="px-4" onClick={registerFunction}>
                          Register
                        </CButton>
                      </CCol>
                      <CCol xs={4} className="text-right d-md-flex justify-content-md-end">
                        <Link to="/login">
                          <CButton color="warning">
                            Login
                          </CButton>
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              {/* <CCard className="text-white bg-primary py-5 w-30" style={{ width: '100px' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Register Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard> */}
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Register