import { React } from 'react'
import { Link } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilCheckCircle } from '@coreui/icons'
// import axios from 'axios'

const RegisterSuccess = () => {
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>                    
              <CCard className="text-white bg-primary py-5">
                <CCardBody className="text-center">
                  <div>
                    <CIcon icon={cilCheckCircle} size="xxl"/>  
                  </div>
                  <div>
                    <h2>Success</h2>
                    <p>
                      Your registration is successfull. You will get confirmation email after approving your account.
                    </p>
                    <Link to="/">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Home
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default RegisterSuccess
