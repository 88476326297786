import { React, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CAlert
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'

const ForgotPassword = () => {

  // console.info(TokenKey)

  
  const recaptchaRef = useRef();
  // Recaptcha OnChange
  function reCaptchaonChange(value) {
    // console.log("Captcha value:", value);
    console.log("Recaptcha Success")
  }
    // const ErrorAlert = document.getElementById('error_msg')

    // Error Message
    const [errorMsgAlert, seterrorMsgAlert] = useState('')
    const [alertBox, setalertBox] = useState(false)

    // Success Message
    const [successMsgAlert, setsuccessMsgAlert] = useState('')
    const [successBox, setsuccessBox] = useState(false)

    const forgetFunction = () => {
      let email = document.getElementById("userEmail").value;
      let forgotPasswordRecaptcha = recaptchaRef.current.getValue();
  
      // Should use same Key values from API
      const userCredentials = { email, forgotPasswordRecaptcha }
  
      // console.info(userCredentials)
      axios.post("https://api.indgiants.in/api/forgotpassword", userCredentials)
      .then(res => {
        if (res.status === 200) {
          // console.info(res.data.message)
          const success_message = res.data.message;
          setsuccessMsgAlert(success_message)
          setsuccessBox(true)
          setalertBox(false)
        } else {
          return
        }
      })
      .catch((error) => {
        error = error.response.data.message;
        seterrorMsgAlert(error);
        setalertBox(true)
        setsuccessBox(false)
      })
    }


  // let TokenKey = localStorage.getItem("token")
  // console.info(TokenKey)

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <>
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md={8}>
                <CCardGroup>
                  <CCard className="p-4" style={{ textAlign: "left" }}>
                    <CCardBody>
                      <CForm>
                        <h1>Forget Password</h1>
                        <p className="text-medium-emphasis">Sign In to your account</p>
                        {
                          alertBox ? 
                          <CAlert color="danger" id="error_msg">
                            { errorMsgAlert }
                          </CAlert>
                        :
                        null
                        }

                        {
                          successBox ? 
                          <CAlert color="success" id="success_msg">
                            { successMsgAlert }
                          </CAlert>
                          :
                          null
                        }

                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilUser} />
                          </CInputGroupText>
                          <CFormInput placeholder="Email" autoComplete="email" id="userEmail" />
                        </CInputGroup>
                        <CRow>
                          <ReCAPTCHA 
                            ref={recaptchaRef}
                            sitekey="6LcGpxkdAAAAAFvFV2Y88FpY4mbHJNQVBwEe9lPx"
                            onChange={reCaptchaonChange}
                            className="mb-3"
                          />
                        </CRow>
                        <CRow>
                          <CCol xs={12}>
                            <CButton color="primary" className="px-4" onClick={forgetFunction}>
                              Send
                            </CButton>
                          </CCol>
                        </CRow>
                      </CForm>
                    </CCardBody>
                  </CCard>
                  <CCard className="text-white bg-primary py-5">
                    <CCardBody className="text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <div>
                        <h2>Sign up</h2>
                        <p>
                          If you don&apos;t have an account! Please create one.
                        </p>
                        <Link to="/register">
                          <CButton color="primary" className="mt-3" active tabIndex={-1}>
                            Register Now!
                          </CButton>
                        </Link>
                      </div>
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        </>
    </div>
    
  )
}

export default ForgotPassword
