import { React, useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom';
import {
  CButton,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormFeedback,
  CFormLabel,
  CFormTextarea,
  CRow,
} from '@coreui/react'
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../App.css';

// Context Provider for Getting Logged In User Email ID
import { userContext } from '../components/Routing';

toast.configure()

const AddData = () => {
  
  const user_Details = useContext(userContext);  
  const params = useParams();
  const [appNamefromParams, setAppNamefromParams] = useState("")

  useEffect(() => {
    setAppNamefromParams(params)
  }, [params])

  const caller_email = user_Details.email;
  const caller_id = user_Details.id;

  const handleSubmit = (event) => {
    // const form = event.currentTarget
    event.preventDefault()

    let application = document.getElementById('applicationName').value;
    let mobile_number = document.getElementById('mobileNumber').value;
    let school_name = document.getElementById('schoolName').value;
    let school_address = document.getElementById('schoolAddress').value;

    var callAttemptsRadio = document.getElementsByName('callAttempts');
    var i;
    var call_attempts = "N/A"
    for(i = 0; i < callAttemptsRadio.length; i++) {
      if(callAttemptsRadio[i].checked)
        call_attempts = callAttemptsRadio[i].value
    }

    var callStatusRadio = document.getElementsByName('callStatus');
    var s;
    var call_status = "N/A"
    for(s = 0; s < callStatusRadio.length; s++) {
      if(callStatusRadio[s].checked)
        call_status = callStatusRadio[s].value
    }

    let call_summary = document.getElementById('callSummary').value;
    if(call_summary === ""){
      call_summary = "N/A"
    }
    let incharge_name = document.getElementById('inchargeName').value;
    if(incharge_name === ""){
      incharge_name = "N/A"
    }
    let incharge_designation = document.getElementById('inchargeDesignation').value;
    if(incharge_designation === ""){
      incharge_designation = "N/A"
    }

    var teachingMethodRadio = document.getElementsByName('teachingMethod');
    var t;
    var current_teaching_method = "N/A"
    for(t = 0; t < teachingMethodRadio.length; t++) {
      if(teachingMethodRadio[t].checked)
      current_teaching_method = teachingMethodRadio[t].value
    }

    var otherPlatformRadio = document.getElementsByName('otherPlatform');
    var p;
    var other_platform = "N/A"
    for(p = 0; p < otherPlatformRadio.length; p++) {
      if(otherPlatformRadio[p].checked)
        other_platform = otherPlatformRadio[p].value
    }

    var inchargeResponseRadio = document.getElementsByName('inchargeResponse');
    var r;
    var incharge_response = "N/A"
    for(r = 0; r < inchargeResponseRadio.length; r++) {
      if(inchargeResponseRadio[r].checked)
        incharge_response = inchargeResponseRadio[r].value
    }
    
    let tele_caller_opinion = document.getElementById('teleCallerOpinion').value;
    if(tele_caller_opinion == ""){
      tele_caller_opinion = "N/A"
    }

    const allData = {
                    caller_id,
                    caller_email,
                    application,
                    mobile_number,
                    school_name,
                    school_address,
                    call_attempts,
                    call_status,
                    call_summary,
                    incharge_name,
                    incharge_designation,
                    current_teaching_method,
                    other_platform,
                    incharge_response,
                    tele_caller_opinion
                  }

      axios.post(`https://api.indgiants.in/api/add-data`, allData)
      .then(res => {
        if(res.status === 200 || res.status === 201){
          toast.success("Data added successfully!");
          // MindHosts();
        }
      })
      .catch((error) => {
        return
      });
  }

  return (
    <CRow className='centerColumn mt-4 mb-4'>
      <CCol xs={11}>
        <CForm
          className="row g-3 needs-validation add-data-form"
          // noValidate
          // validated={validated}
          onSubmit={handleSubmit}
        >
          <CCol md={12}>
            <CFormLabel htmlFor="validationCustom02">Application</CFormLabel>
            <CFormInput type="text" id="applicationName" defaultValue={appNamefromParams.app} disabled required />
            <CFormFeedback valid>Looks good!</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormLabel htmlFor="validationCustom01">In-Charge Mobile</CFormLabel>
            <CFormInput type="text" id="mobileNumber" required />
            <CFormFeedback valid>Looks good!</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormLabel htmlFor="validationCustom02">Name of the School/Organization</CFormLabel>
            <CFormInput type="text" id="schoolName" required/>
            <CFormFeedback valid>Looks good!</CFormFeedback>
          </CCol>
          <CCol md={12}>
            <CFormLabel htmlFor="validationCustom01">Address</CFormLabel>
            <CFormTextarea id="schoolAddress" rows="2" required></CFormTextarea>
            <CFormFeedback valid>Looks good!</CFormFeedback>
          </CCol>
          <CCol md={4} className="mt-20">
            <CCol md={12} className="mb-0">
              <CFormLabel htmlFor="validationCustom01">Call Attempts</CFormLabel>
            </CCol>
              <div>
                <CFormCheck type="radio" name="callAttempts" inline id="callAttempt1" value="1" label="1" />
                <CFormCheck type="radio" name="callAttempts" inline id="callAttempt2" value="2" label="2" />
                <CFormCheck type="radio" name="callAttempts" inline id="callAttempt3" value="3" label="3" />
                <CFormCheck type="radio" name="callAttempts" inline id="callAttempt4" value="4" label="4" />
              </div>
            <CFormFeedback valid>Looks good!</CFormFeedback>
          </CCol>
          <CCol md={8} className="mt-20">
            <CCol md={12}>
              <CFormLabel htmlFor="validationCustom01">Call Status</CFormLabel>
            </CCol>
              <div>
                <CFormCheck type="radio" name="callStatus" inline id="callStatusConnected" value="Connected" label="Connected" />
                <CFormCheck type="radio" name="callStatus" inline id="callStatusSwitchOff" value="Switched-Off" label="Switched-Off" />
                <CFormCheck type="radio" name="callStatus" inline id="callStatusNotExist" value="Does Not Exist" label="Does Not Exist" />
                <CFormCheck type="radio" name="callStatus" inline id="callStatusBusy" value="Busy" label="Busy" />
                <CFormCheck type="radio" name="callStatus" inline id="callStatusOther" value="Other" label="Other" />
              </div>
            {/* <CFormInput className="mt-2" inline type="text" id="validationCustom02" placeholder="If other" /> */}
            <CFormFeedback valid>Looks good!</CFormFeedback>
          </CCol>
          <CCol md={12}>
            <CFormLabel htmlFor="validationCustom01">Call Summary</CFormLabel>
            <CFormTextarea id="callSummary" rows="2"></CFormTextarea>
            <CFormFeedback valid>Looks good!</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormLabel htmlFor="validationCustom01">Name of the In-Charge</CFormLabel>
            <CFormInput type="text" id="inchargeName" />
            <CFormFeedback valid>Looks good!</CFormFeedback>
          </CCol>
          <CCol md={6}>
            <CFormLabel htmlFor="validationCustom02">Designation</CFormLabel>
            <CFormInput type="text" id="inchargeDesignation" />
            <CFormFeedback valid>Looks good!</CFormFeedback>
          </CCol>
          <CCol md={6} className="mt-20">
            <CCol md={12}>
              <CFormLabel htmlFor="validationCustom01">Teaching Method Adopted Currently</CFormLabel>
            </CCol>
              <div>
                <CFormCheck type="radio" name="teachingMethod" inline id="teachingMethodOffline" value="Offline" label="Offline" />
                <CFormCheck type="radio" name="teachingMethod" inline id="teachingMethodOnline" value="Online" label="Online" />
                <CFormCheck type="radio" name="teachingMethod" inline id="teachingMethodBoth" value="Both" label="Both" />
              </div>
            <CFormFeedback valid>Looks good!</CFormFeedback>
          </CCol>
          <CCol md={6} className="mt-20">
            <CCol md={12}>
              <CFormLabel htmlFor="validationCustom01">Online Platform being used</CFormLabel>
            </CCol>
              <div>
                <CFormCheck type="radio" name="otherPlatform" inline id="otherPlatform1" value="Zoom" label="Zoom" />
                <CFormCheck type="radio" name="otherPlatform" inline id="otherPlatform2" value="Google" label="Google or Other Video" />
                <CFormCheck type="radio" name="otherPlatform" inline id="otherPlatform3" value="Third-Party" label="Third-Party" />
                {/* <CFormInput className="mt-2" type="text" id="validationCustom02" placeholder="If other" /> */}
              </div>
            <CFormFeedback valid>Looks good!</CFormFeedback>
          </CCol>
          <CCol md={12}>
            <CFormLabel htmlFor="validationCustom01">Response</CFormLabel>
          </CCol>
          <CCol md={12} className="mt-0">
              <div className=''>
                <CFormCheck type="radio" name="inchargeResponse" inline id="inchargeResponse1" value="Interested" label="Interested" />
                <CFormCheck type="radio" name="inchargeResponse" inline id="inchargeResponse2" value="Not Interested" label="Not Interested" />
                <CFormCheck type="radio" name="inchargeResponse" inline id="inchargeResponse3" value="Another-Platform" label="Another-Platform" />
                <CFormCheck type="radio" name="inchargeResponse" inline id="inchargeResponse4" value="Low Budget" label="Low Budget" />
                <CFormCheck type="radio" name="inchargeResponse" inline id="inchargeResponse5" value="No Need" label="No Need" />
              </div>
            <CFormFeedback valid>Looks good!</CFormFeedback>
          </CCol>
          <CCol md={12}>
            <CFormLabel htmlFor="validationCustom01">Opinion of the Tele-Caller</CFormLabel>
            <CFormTextarea id="teleCallerOpinion" rows="3"></CFormTextarea>
            <CFormFeedback valid>Looks good!</CFormFeedback>
          </CCol>
          <CCol md={12}>
            <CButton color="primary" type="submit">
              Submit form
            </CButton>
          </CCol>
        </CForm>
      </CCol>
    </CRow>
  )
}

export default AddData;