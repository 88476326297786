import {useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from "react-router-dom";
import { 
    CSidebarToggler,
    CSidebar,
    CSidebarBrand,
    CSidebarNav,
    CNavGroup,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilDescription,
  cilNoteAdd,
  cilUser,
  cilPhone,
  cilAccountLogout
} from '@coreui/icons'
import "../App.css";
import {sygnet} from '../assets/sygnet';
import logo from "../assets/logo.JPG";
import { userContext } from '../components/Routing';

const Sidebar = () => {

    const user_Details = useContext(userContext)
    const [Loading, setLoading] = useState(true)
    const [GetCallerData, setGetCallerData] = useState([])
    const callerId = GetCallerData.id;

    const gettingCallerDetails = () => {
        if(user_Details.first_name !== undefined){
            setGetCallerData(user_Details)
            setLoading(false)
        }
    }

    useEffect(() => {
        gettingCallerDetails();
    }, [user_Details])

    const [getCompanies, setGetCompanies] = useState([]);
    
    const getAssignedCompany = async() => {
        const formData = new FormData();
        formData.append("caller_id", callerId)

        let url = await fetch("https://api.indgiants.in/api/caller-apps", {
            method: "POST",
            body: formData
        })
        .then((res) => res.json())
        .then((res) => {
            setGetCompanies(res)
        })
        .catch((err) => {
            console.error(err)
        })
    }

    useEffect(() => {
        getAssignedCompany();
    }, [callerId])

    const logoutFunction = () => {
        localStorage.removeItem("session_token")
        window.location.reload(false)
    }

    const dispatch = useDispatch()
    const unfoldable = useSelector((state) => state.sidebarUnfoldable)
    const sidebarShow = useSelector((state) => state.sidebarShow)

    return (
        <CSidebar
            position="fixed"
            unfoldable={unfoldable}
            visible={sidebarShow}
            onVisibleChange={(visible) => {
                dispatch({ type: 'set', sidebarShow: visible })
            }}
            >
            <CSidebarBrand className="d-none d-md-flex" to="/" style={{ background: "#fff" }}>
                {/* <CAvatar className="sidebar-brand-full" icon={logo} height={35} /> */}
                {/* <CAvatar src={logo} size="lg" /> */}
                <img src={logo} alt="fireSpot" style={{ width: '60%' }}/>
                <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
            </CSidebarBrand>
                {
                    Loading ?
                    null
                    :
                    <CSidebarNav>
                        {/* <CNavTitle>Nav Title</CNavTitle> */}
                        {/* <CNavItem href="#">
                        <CIcon icon={cilDescription} customClassName="nav-icon" />
                            All Data
                        <CBadge color="primary ms-auto">NEW</CBadge>
                        </CNavItem> */}
                        <Link to="/" className="link-div" style={{ paddingTop: "20px" }}>
                            <CIcon icon={cilUser} customClassName="nav-icon" />
                                Dashboard
                        </Link>
                        <CNavGroup toggler="Add Data">
                            {
                                getCompanies ?
                                <>
                                    {
                                        getCompanies.map((app) =>
                                            <Link to={`add-${app.caller_app.toLowerCase()}`} className="link-div">
                                                <CIcon icon={cilNoteAdd} customClassName="nav-icon" />
                                                {app.caller_app}
                                            </Link>
                                        )
                                    }
                                </>
                                :
                                null
                            }
                        </CNavGroup>

                        {/* Show Data */}
                        <CNavGroup toggler="Show Data">
                            {
                                getCompanies ?
                                <>
                                    {
                                        getCompanies.map((app) =>
                                            <Link to={`show-${app.caller_app.toLowerCase()}`} className="link-div">
                                                <CIcon icon={cilDescription} customClassName="nav-icon" />
                                                {app.caller_app}
                                            </Link>
                                        )
                                    }
                                </>
                                :
                                null
                            }
                        </CNavGroup>

                        <Link to="profile" className="link-div">
                            <CIcon icon={cilUser} customClassName="nav-icon" />
                                Profile
                        </Link>

                        <Link to="about" className="link-div">
                            <CIcon icon={cilDescription} customClassName="nav-icon" />
                                About
                        </Link>

                        <Link to="support" className="link-div">
                            <CIcon icon={cilPhone} customClassName="nav-icon" />
                                Support
                        </Link>

                        <div className="link-div pointer-cursor" onClick={() => logoutFunction()}>
                            <CIcon icon={cilAccountLogout} customClassName="nav-icon" />
                                Logout
                        </div>

                    </CSidebarNav>
                }
            <CSidebarToggler
                className="d-none d-lg-flex"
                onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
            />
        </CSidebar>
    )
}

export default Sidebar;